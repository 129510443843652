<template>
  <v-btn
    @click="download"
    color="green"
    class="white--text"
    small
    :loading="downloadLoading"
  >
    {{'action.download'| t}}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import downloadjs from 'downloadjs'
import listActionMixins from '@/components/list/listDataAction/listDataAction.js'
export default {
  mixins: [listActionMixins],
  data: () => ({
    downloadLoading: false,
  }),
  methods: {
    async download() {
      // 可預覽類型
      if(this.isPreviewType) {
        window.open(this.fileUrl)
        return
      }

      // 不可預覽, 直接跳下載確認popup
      this.$apopup.base({
        title: this.$t('download.confirm'),
        content: `${this.filename}`,
        applyCallback: () => {
          this.startDownloadFileRequest()
        },
      })
    },
    async startDownloadFileRequest() {
      if(this.downloadLoading == true) return
      this.downloadLoading = true
      try {
        const downloadResult = await this.downloadFileRequest({
          path: this.file.path,
        })

        const data = downloadResult.data
        downloadjs(new Blob([data]), this.filename, data.type)
      } catch (error) {
        console.error(error)
      } finally {
        this.downloadLoading = false
      }
    },
  },
  computed: {
    file() {
      return this.row
    },
    filename() {
      if(!this.file) return null
      return this.file.filename
    },
    isPreviewType() {
      if(this.file.file_type === 'text/plain') return true
      if(this.file.file_type === 'application/pdf') return true
      if(this.file.file_type === 'application/json') return true
      if(this.file.file_type === 'text/html') return true
      if(new RegExp('image/').test(this.file.file_type)) return true
      return false
    },
    downloadFileRequest() {
      return this.$api.collection.fileApi.download
    },
    fileBaseUrl() {
      return this.$store.getters['base/application'].fileBaseUrl
    },
    fileUrl() {
      if(!this.file) return null
      const path = window.eagleLodash.cloneDeep(this.file.path).substring(1)
      return `${this.fileBaseUrl}${path}`
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>